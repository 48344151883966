var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "site-footer" }, [
      _c("div", { staticClass: "text-right" }, [
        _vm._v("\n    Developed By \n    "),
        _c(
          "a",
          {
            attrs: {
              href: "https://github.com/SaadRizwanKhan/",
              target: "_blank"
            }
          },
          [_vm._v("Saad Rizwan Khan\n    ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }