<template>
  <div class="login-page login-3">
    <div class="site-wrapper">
      <div class="login-box">
        <div class="box-wrapper">
          <div class="logo-main">
            <a href="/admin">
              <img
                src="/assets/img/main-logo.png"
                alt="Main Logo" style="width:100px !important; height:100px !important">
            </a>
          </div>
          <router-view></router-view>
          <div class="page-copyright">
            <!-- <p> &copy; Saad Rizwan Khan 2020 </p> -->
          </div>
        </div>
      </div>
      <div class="content-box">
        <h1>
          Invoice System 💰
        </h1>

        <p style="display:grid;justify-content:end">
          <span></span>

        </p>

        <div class="content-bottom"/>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  watch: {
    $route: 'onRouteChange'
  },
  mounted () {
    this.setLayoutClasses()
  },
  methods: {
    setLayoutClasses () {
      let body = $('body')
      body.removeClass()
      body.addClass('login-page login-1 skin-crater')
    }
  },
  onRouteChange () {
    $('body').removeClass('login-page')
  }
}
</script>
