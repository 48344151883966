var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page login-3" }, [
    _c("div", { staticClass: "site-wrapper" }, [
      _c("div", { staticClass: "login-box" }, [
        _c(
          "div",
          { staticClass: "box-wrapper" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("router-view"),
            _vm._v(" "),
            _c("div", { staticClass: "page-copyright" })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-main" }, [
      _c("a", { attrs: { href: "/admin" } }, [
        _c("img", {
          staticStyle: {
            width: "100px !important",
            height: "100px !important"
          },
          attrs: { src: "/assets/img/main-logo.png", alt: "Main Logo" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-box" }, [
      _c("h1", [_vm._v("\n        Invoice System 💰\n      ")]),
      _vm._v(" "),
      _c("p", { staticStyle: { display: "grid", "justify-content": "end" } }, [
        _c("span")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-bottom" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }