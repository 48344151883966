<template>
  <footer class="site-footer">
    <div class="text-right">
      Developed By 
      <a
        href="https://github.com/SaadRizwanKhan/"
        target="_blank">Saad Rizwan Khan
      </a>
    </div>
  </footer>
</template>

<script type="text/babel">
export default {
  data () {
    return {
      footer: 'footer'
    }
  }
}
</script>
