var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "site-header", staticStyle: { height: "70px" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "nav-toggle",
          attrs: { href: "#" },
          on: { click: _vm.onNavToggle }
        },
        [_vm._m(1)]
      ),
      _vm._v(" "),
      _c("ul", { staticClass: "action-list" }, [
        _c(
          "li",
          [
            _c(
              "v-dropdown",
              { attrs: { "show-arrow": false } },
              [
                _c(
                  "a",
                  {
                    staticClass: "avatar",
                    attrs: {
                      slot: "activator",
                      href: "#",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false"
                    },
                    slot: "activator"
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.profilePicture, alt: "Avatar" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-dropdown-item",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item",
                        attrs: { to: "/admin/settings" }
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "dropdown-item-icon",
                          attrs: { icon: "cogs" }
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("navigation.settings")) + " "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-dropdown-item", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.logout($event)
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "dropdown-item-icon",
                        attrs: { icon: "sign-out-alt" }
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("navigation.logout")) + " ")
                      ])
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "brand-main", attrs: { href: "/" } }, [
      _c("img", {
        staticClass: "d-none d-md-inline",
        staticStyle: { width: "50px !important", height: "50px !important" },
        attrs: {
          id: "logo-white",
          src: "/assets/img/logo-white.png",
          alt: "Crater Logo"
        }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "d-md-none",
        attrs: {
          id: "logo-mobile",
          src: "/assets/img/crater-white-small.png",
          alt: "Laraspace Logo"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hamburger hamburger--arrowturn" }, [
      _c("div", { staticClass: "hamburger-box" }, [
        _c("div", { staticClass: "hamburger-inner" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }